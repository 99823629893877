<template>
    <transition name="modal-fade">
      <div class="modal-backdrop">
        <div class="modal">
  
          <header class="modal-header">
            <slot name="header">
              {{ titre }}
            </slot>
            <button type="button" class="btn-close" @click="closeErrorModel">
              x
            </button>
          </header>
  
        <section class="modal-body">
          <slot name="body">
            {{ text }}
          </slot>
         </section>
  
          <footer class="modal-footer">
            <button type="button" class="btn-green" @click="validerErrorModel">
                Accepter

            </button>
            <button type="button" class="btn-red" @click="closeErrorModel">
               Rejeter
            </button>
          </footer>
  
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    name: 'ConfirmationModel',
    props: {
      text : String,
      titre : String
    },
  
    methods: {
  
      closeErrorModel() {
        this.$emit('closeErrorModel');
      },
  
      validerErrorModel() {
        this.$emit('validerErrorModel');
      },
  
    }
  };
  </script>
  
  <style scoped>
  
    .modal-backdrop {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
  
    }
  
    .modal {
      background: #e5e5e5;
    min-width: 500px;
    max-width: 600px;
    max-height: 400px;
    overflow: auto;
    border-radius: 30px;
    }
  
    .modal-header,
    .modal-footer {
      padding: 15px;
      display: flex;
    }
  
    .modal-header {
      position: relative;
      border-bottom: 1px solid #7e7a7a;
      color: #0e105c;
      text-align: center;
      justify-content: center;
      text-transform: none;
      background-color: white;
   }
  
    .modal-footer {
      border-top: 3px solid #eeeeee;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5px;
    background-color: white;
    }
  
    .modal-body {
      position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
    font-size: 18px;
    background-color: white;
  
    }
  
  

    .btn-close {
      position: absolute;
      top: 0;
      right: 0;
      border: none;
      font-size: 20px;
      padding: 10px;
      cursor: pointer;
      font-weight: bold;
      color: #4AAE9B;
      background: transparent;
      color : red;
    }
  
    .modal-footer > .btn-green {
      border: 0px;
      width: fit-content;
      background-color: green;
      color: white;
      padding: 10px;
      cursor: pointer;
      margin-right: 5px;
      border-radius: 20px;
      height: fit-content;
    }
  
    .modal-footer > .btn-red {
      border: 0px;
      width: fit-content;
      background-color: red;
      color: white;
      padding: 10px;
      cursor: pointer;
      margin-right: 5px;
      border-radius: 20px;
      height: fit-content;
    }
  
     
  
  
  
  </style>