<template>
    <div class="table_component" tabindex="0">

        <div class="buttons">
            <button v-if="!flagThird" @click="retourFirst">Retour</button>
            <button v-if="!flagThird" @click="handelDossier">Créer un dossier</button>
            <button v-if="!flagThird" @click="handelFichier">Téléverser fichier(s)</button>
        </div>

        <table v-if="!flagThird">
            <thead>
                <tr>
                    <th>Désignation / Référence</th>
                    <th>Type</th>
                    <th>Date</th>
                    <th>Créateur /Créatrice</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody v-for="(item, index) in listeTableFirst" :key="index">
                <tr @click="voir(item._id, item.originalname)">
                    <td>{{ item.originalname.length > 60 ? item.originalname.substr(0, 30) : item.originalname }}</td>
                    <td>{{ `${checkTypeFile(item.originalname, item.type)}` }}</td>
                    <td>{{ new Date(item.date).toLocaleDateString() }}</td>
                    <td>{{ item.prenom }} {{ item.nom }}</td>
                    <td>

                        <button class="apercu" v-if="item.type == 'Dossier'"
                            @click="voir(item._id, item.originalname)"><i class="fa fa-arrow-right"></i>Aperçu</button>
                        <button class="telecharger" v-if="item.type == 'Fichier'"
                            @click.stop="download(item.filename, 'second', item.originalname)"><i
                                class="fa fa-download"></i>Télècharger</button>
                        <button class="supprimer" v-if="item.email == email || domain == 'gthconsult.ma'"
                            @click="supprimer(item._id, item.originalname, 'second')"><i
                                class="fa fa-trash"></i>Supprimer</button>
                    </td>
                </tr>
            </tbody>
        </table>

        <DeleteModel v-if="flagDeleteModel" :messageDelete="messageDelete" @validerDeleteModel="validerDeleteModel"
            @closeDeleteModel="closeDeleteModel" />
        <loading v-model:active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage" />
        <Create v-if="flagCreate" :type="type" @closeConfirmation="closeConfirmation"
            @validerConfirmationDossier="validerConfirmationDossier"
            @validerConfirmationFichier="validerConfirmationFichier" />

        <TableThird v-if="flagThird" :idSecond="idSecond" :titreSecond="titreSecond" :email="email"
            @retourSecond="retourSecond" />

    </div>
</template>

<script>
import Create from "@/components/models/Transfer/Create.vue"
import TableThird from "@/components/models/Transfer/TableThird.vue"
import DeleteModel from "@/components/models/DeleteModel.vue"
import Espace from "@/connection/Espace"
import Loading from 'vue-loading-overlay';


export default {
    name: 'table_component',
    data() {
        return {
            flagDeleteModel: false,
            messageDelete: "",
            idDeleteModel: null,
            originalnameDeleteModel: null,
            valueDeleteModel: null,
            listeTableFirst: [],
            flagCreate: false,
            type: null,
            flagThird: false,
            idSecond: null,
            titreSecond: null,
            domain: null,
        }
    },
    props: {
        idFirst: String,
        titreFirst: String,
        email: String
    },

    components: {
        Loading,
        Create,
        TableThird,
        DeleteModel
    },

    methods: {
        checkTypeFile(value) {
            if (!value.includes('.')) {
                return 'Folder'; // Si aucune extension, c'est un dossier
            }

            const extention = value.split('.').pop().toLowerCase(); // Récupère la dernière extension en minuscules

            switch (extention) {
                // Documents
                case 'pdf':
                    return 'Pdf';
                case 'txt':
                    return 'Txt';
                case 'docx':
                case 'doc':
                    return 'Word';
                case 'xls':
                case 'xlsx':
                    return 'Excel';
                case 'csv':
                    return 'CSV';
                case 'ppt':
                case 'pptx':
                    return 'Power point';

                // Images
                case 'jpg':
                case 'jpeg':
                    return 'Image Jpeg';
                case 'png':
                    return 'Image PNG';
                case 'gif':
                    return 'Image GIF';
                case 'bmp':
                    return 'Image BMP';
                case 'webp':
                    return 'Image WebP';
                case 'svg':
                    return 'Image SVG';

                // Vidéos
                case 'mp4':
                    return 'Vidéo MP4';
                case 'avi':
                    return 'Vidéo AVI';
                case 'mov':
                    return 'Vidéo MOV';
                case 'mkv':
                    return 'Vidéo MKV';
                case 'flv':
                    return 'Vidéo FLV';

                // Audio
                case 'mp3':
                    return 'Audio MP3';
                case 'wav':
                    return 'Audio WAV';
                case 'aac':
                    return 'Audio AAC';
                case 'flac':
                    return 'Audio FLAC';

                // Archives
                case 'zip':
                    return 'Archive ZIP';
                case 'rar':
                    return 'Archive RAR';
                case '7z':
                    return 'Archive 7z';
                case 'tar':
                    return 'Archive TAR';

                // Code & développement
                case 'html':
                    return 'Fichier HTML';
                case 'css':
                    return 'Fichier CSS';
                case 'js':
                    return 'Fichier JavaScript';
                case 'json':
                    return 'Fichier JSON';
                case 'xml':
                    return 'Fichier XML';
                case 'php':
                    return 'Fichier PHP';
                case 'py':
                    return 'Fichier Python';
                case 'java':
                    return 'Fichier Java';
                case 'cpp':
                    return 'Fichier C++';
                case 'c':
                    return 'Fichier C';
                case 'ts':
                    return 'Fichier TypeScript';

                default:
                    return 'Unknown file type';
            }
        },



        supprimer(id, originalname, value) {
            this.messageDelete = "Êtes-vous sûr de vouloir supprimer cet élément ?",
                this.idDeleteModel = id;
            this.originalnameDeleteModel = originalname;
            this.valueDeleteModel = value;
            this.flagDeleteModel = true;
        },

        closeDeleteModel() {
            this.flagDeleteModel = false;
        },

        validerDeleteModel() {
            Espace.supprimer(this.idDeleteModel, this.originalnameDeleteModel, this.valueDeleteModel)
                .then((result) => {
                    if (result == true) {
                        const index = this.listeTableFirst.findIndex((el) => el._id == this.idDeleteModel)
                        this.listeTableFirst.splice(index, 1);
                        this.flagDeleteModel = false;
                        this.messageDelete = "";
                        this.idDeleteModel = null;
                        this.originalnameDeleteModel = null;
                        this.valueDeleteModel = null;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        downloadFile(response, title, typeContent) {

            var newBlob = new Blob([response.data], {
                type: typeContent
            });

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }

            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement("a");
            link.href = data;
            link.download = title;
            link.click();
            setTimeout(function () {
                window.URL.revokeObjectURL(data);
            }, 100);

        },

        download(filename, value, originalname) {
            const extention = originalname.split('.').pop().toLowerCase();

            let typeContent = null;

            switch (extention) {
                // Formats Word
                case 'doc':
                    typeContent = 'application/msword';
                    break;
                case 'docx':
                    typeContent = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                    break;
                case 'docm':
                    typeContent = 'application/vnd.ms-word.document.macroEnabled.12';
                    break;
                case 'dot':
                    typeContent = 'application/msword';
                    break;
                case 'dotx':
                    typeContent = 'application/vnd.openxmlformats-officedocument.wordprocessingml.template';
                    break;
                case 'dotm':
                    typeContent = 'application/vnd.ms-word.template.macroEnabled.12';
                    break;
                case 'rtf':
                    typeContent = 'application/rtf';
                    break;
                case 'odt':
                    typeContent = 'application/vnd.oasis.opendocument.text';
                    break;

                // Formats Excel
                case 'xls':
                    typeContent = 'application/vnd.ms-excel';
                    break;
                case 'xlsx':
                    typeContent = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                    break;
                case 'xlsm':
                    typeContent = 'application/vnd.ms-excel.sheet.macroEnabled.12';
                    break;
                case 'xltx':
                    typeContent = 'application/vnd.openxmlformats-officedocument.spreadsheetml.template';
                    break;
                case 'xltm':
                    typeContent = 'application/vnd.ms-excel.template.macroEnabled.12';
                    break;
                case 'csv':
                    typeContent = 'text/csv';
                    break;

                // Formats PDF
                case 'pdf':
                    typeContent = 'application/pdf';
                    break;

                // Formats PowerPoint
                case 'ppt':
                    typeContent = 'application/vnd.ms-powerpoint';
                    break;
                case 'pptx':
                    typeContent = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
                    break;

                // Archives
                case 'zip':
                    typeContent = 'application/zip';
                    break;
                case 'rar':
                    typeContent = 'application/vnd.rar';
                    break;
                case '7z':
                    typeContent = 'application/x-7z-compressed';
                    break;
                case 'tar':
                    typeContent = 'application/x-tar';
                    break;
                case 'gz':
                    typeContent = 'application/gzip';
                    break;

                // Images
                case 'jpeg':
                case 'jpg':
                    typeContent = 'image/jpeg';
                    break;
                case 'png':
                    typeContent = 'image/png';
                    break;
                case 'gif':
                    typeContent = 'image/gif';
                    break;
                case 'bmp':
                    typeContent = 'image/bmp';
                    break;
                case 'svg':
                    typeContent = 'image/svg+xml';
                    break;
                case 'webp':
                    typeContent = 'image/webp';
                    break;

                // Vidéos
                case 'mp4':
                    typeContent = 'video/mp4';
                    break;
                case 'avi':
                    typeContent = 'video/x-msvideo';
                    break;
                case 'mov':
                    typeContent = 'video/quicktime';
                    break;
                case 'wmv':
                    typeContent = 'video/x-ms-wmv';
                    break;
                case 'flv':
                    typeContent = 'video/x-flv';
                    break;
                case 'mkv':
                    typeContent = 'video/x-matroska';
                    break;

                // Audio
                case 'mp3':
                    typeContent = 'audio/mpeg';
                    break;
                case 'wav':
                    typeContent = 'audio/wav';
                    break;
                case 'ogg':
                    typeContent = 'audio/ogg';
                    break;
                case 'flac':
                    typeContent = 'audio/flac';
                    break;

                // Code & Web
                case 'html':
                    typeContent = 'text/html';
                    break;
                case 'css':
                    typeContent = 'text/css';
                    break;
                case 'js':
                    typeContent = 'application/javascript';
                    break;
                case 'json':
                    typeContent = 'application/json';
                    break;
                case 'xml':
                    typeContent = 'application/xml';
                    break;
                case 'php':
                    typeContent = 'application/x-httpd-php';
                    break;

                // Police (Fonts)
                case 'ttf':
                    typeContent = 'font/ttf';
                    break;
                case 'woff':
                    typeContent = 'font/woff';
                    break;
                case 'woff2':
                    typeContent = 'font/woff2';
                    break;

                // Fichiers par défaut (non pris en charge)
                default:
                    typeContent = 'application/octet-stream';
                    break;
            }


            Espace.download(filename, value)
                .then((result) => {
                    this.downloadFile(result, originalname, typeContent);
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        voir(id, name) {
            this.idSecond = id;
            this.titreSecond = name;
            this.flagThird = true;
        },

        retourSecond() {
            this.flagThird = false;
        },

        retourFirst() {
            return this.$emit("retourFirst");
        },

        modifier(id) {
            console.log(id);
        },

        handelDossier() {
            this.type = "dossier";
            this.flagCreate = true;
        },

        handelFichier() {
            this.type = "fichier";
            this.flagCreate = true;
        },

        closeConfirmation() {
            this.type = null;
            this.flagCreate = false;
        },

        validerConfirmationDossier(name) {

            this.isLoading = true;
            this.type = null;
            this.flagCreate = false;

            Espace.soumetterSecondDossier(this.email, name, this.idFirst)
                .then((result) => {
                    this.listeTableFirst.unshift(result.data)
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error.message)
                })

        },


        // valider fichier
        validerConfirmationFichier(document) {

            this.type = null;
            this.flagCreate = false;

            Espace.soumetterSecondFichier(this.email, document, this.idFirst)
                .then((result) => {
                    for (let i = 0; i < result.data.length; i++) {
                        this.listeTableFirst.unshift(result.data[i]);
                    }
                    result.data = [];
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error.message);
                })


        },

    },

    created() {

        const val = this.email.split("@");
        this.domain = val[1];

        Espace.readSecond(this.idFirst)
            .then((result) => {
                console.log(result.data)
                this.listeTableFirst = result.data.listeTow;
            })
            .catch((error) => {
                console.log(error);
            });
    }
};
</script>

<style scoped>
.table_component {
    overflow: auto;
    width: 100%;
}

.table_component .buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
}

.table_component .buttons button {
    margin-left: 5px;
    margin-right: 5px;
    color: white;
    padding: 5px;
    transition: 1s;
    cursor: pointer;
    font-size: small;
    border-radius: 10px;
}

.table_component .buttons button:nth-child(1) {
    background-color: #616161;
}

.table_component .buttons button:nth-child(1):hover {
    background-color: black;
}

.table_component .buttons button:nth-child(2) {
    background-color: #00a419;
}

.table_component .buttons button:nth-child(2):hover {
    background-color: green;
}


.table_component .buttons button:nth-child(3) {
    background-color: orange;
}

.table_component .buttons button:nth-child(3):hover {
    background-color: orangered;
}

.table_component table {
    border: 1px inset #dededf;
    height: 100%;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 1px;
    text-align: left;
}

.table_component caption {
    caption-side: top;
    text-align: left;
}

.table_component th {
    border: 1px inset #dededf;
    background-color: #777c7e;
    color: #ffffff;
    padding: 5px;
}

.table_component td {
    border: 1px inset #dededf;
    background-color: #f7f7f7;
    color: #000000;
    padding: 5px;
}

.apercu,
.telecharger {
    color: white;
    background-color: #348334;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 25px;
}

.apercu:hover,
.telecharger:hover {
    background-color: green
}

.supprimer {
    color: white;
    background-color: #d31111b0;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 25px;
}

.supprimer:hover {
    background-color: red;
}

.fa {
    margin-right: 5px;
}

table>tbody>tr>td:nth-child(5) {
    display: flex;
    flex-direction: row;
}

table>tbody>tr>td:nth-child(5) button {
    margin-left: 10px;
    margin-right: 10px;
}


table tr th:nth-child(1) {
    width: 600px;
}

table tr td:nth-child(1) {
    width: 600px;
}
</style>