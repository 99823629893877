<template>

  <div class="dashboard">

    <div class="navbar">

      <div>
        <img :src="url" />
      </div>

      <ul>
        <li>{{ nomSociete }}</li>
        <li>{{ adresse }} {{ pays }}</li>
      </ul>

      <ul>
        <li>
          <font-awesome-icon icon="fa-solid fa-user" class="fa-user" />
        </li>
        <li>
          <span>{{ nom }} {{ prenom }}</span>
          <span>{{ email }}</span>
        </li>
      </ul>

      <ul>
        <li><button @click="deconnexion">Deconnexion</button></li>
      </ul>

    </div>

    <div class="content" v-if="!flagTableFirst">

      <div class="top">
        <!-- Espaces -->
        <p class="raison-social">Raison Social :</p>
        <select v-if="flagAdmin && flagEspace" v-model="espace" @change="selectEspace">
          <option v-for="(item, index) in espaces" :key="index" :value="item._id">{{ item.raisonSocial }}</option>
        </select>

        <button class="creer-dossier" v-if="flagHandelDossier" @click="handelDossier">Créer un dossier</button>
        <button class="televerser" v-if="flagHandelDossier" @click="handelFichier">Téléverser fichier(s)</button>
      </div>

      <div class="center">
        <ul>
          <li v-for="(parent, index) in listeParent" :key="index" :title="parent.originalname"
            @click="voir(parent._id, index)">
            <!-- Logo folder -->
            <p class="folder" v-if="parent.type == 'Dossier'"><i class="fa fa-folder"></i></p>
            <!-- logo file -->
            <p class="file" v-if="parent.type == 'Fichier'"><i class="fa fa-file"></i></p>
            <!-- Orginal Name -->
            <p>{{ parent.originalname.length > 40 ? parent.originalname.substr(0, 30) : parent.originalname }}</p>
            <p>| {{ parent.nom }} {{ parent.prenom }}</p>
            <P>| {{ `${parent.type} ${checkTypeFile(parent.originalname)}` }} |</P>
            <!-- Date create file -->
            <p>{{ new Date(parent.date).toLocaleDateString() }}</p>
            <!-- Apercu  -->
            <button class="apercu" v-if="parent.type == 'Dossier'" @click="voir(parent._id, index)"><i
                class="fa fa-arrow-right"></i>Aperçu</button>
            <!-- Télécharger -->
            <button class="telecharger" v-if="parent.type == 'Fichier'"
              @click="download(parent.filename, 'parent', parent.originalname)"><i
                class="fa fa-download"></i>Télécharger</button>
            <!-- Supprimer -->
            <button class="supprimer" v-if="parent.emailInterlocuteur == email && domain[1] == 'gthconsult.ma'"
              @click="supprimer(parent._id, parent.originalname, 'parent')"><i
                class="fa fa-trash"></i>Supprimer</button>
            <!-- Permission -->
            <button class="key" v-if="flagAdmin && parent.emailInterlocuteur == email" @click="access(parent._id)"><i
                class="fa fa-key"></i>Permission</button>
            <!-- les access -->
            <p v-if="parent.partager.length != 0 && flagAdmin && parent.emailInterlocuteur == email">
              <select>
                <option v-for="item in parent.partager" :key="item">{{ item }}</option>
              </select>
            </p>
          </li>
        </ul>

      </div>
    </div>

    <DeleteModel v-if="flagDeleteModel" :messageDelete="messageDelete" @validerDeleteModel="validerDeleteModel"
      @closeDeleteModel="closeDeleteModel" />
    <Access v-if="flagAccess" :interlocuteurs="interlocuteurs" :idAccess="idAccess" @validerAccess="validerAccess"
      @closeAccess="closeAccess" />
    <Create v-if="flagCreate" :type="type" @closeConfirmation="closeConfirmation"
      @validerConfirmationDossier="validerConfirmationDossier"
      @validerConfirmationFichier="validerConfirmationFichier" />
    <loading v-model:active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage" />
    <TableFirst v-if="flagTableFirst" :idParent="idParent" :titreParent="titreParent" :email="email"
      @retourPageParent="retourPageParent" />

  </div>
</template>

<script>
import Interlocuteur from "@/connection/Interlocuteur"
import Espace from "@/connection/Espace"
import Create from "@/components/models/Transfer/Create.vue"
import Access from "@/components/models/Transfer/Access.vue"
import TableFirst from "@/components/models/Transfer/TableFirst.vue"
import DeleteModel from "@/components/models/DeleteModel.vue"
import { useCookies } from "vue3-cookies";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  data() {
    return {
      flagDeleteModel: false,
      messageDelete: "",
      idDeleteModel: null,
      originalnameDeleteModel: null,
      valueDeleteModel: null,
      isLoading: false,
      adresse: null,
      email: null,
      emailCompany: null,
      nom: null,
      nomSociete: null,
      pays: null,
      prenom: null,
      url: null,
      ville: null,
      flagCreate: false,
      listeParent: [],
      type: null,
      flagTableFirst: false,
      idParent: null,
      titreParent: null,
      flagInterlocuteur: false,
      preInterlocuteurs: [],
      interlocuteurs: [],
      interlocuteur: null,
      flagEspace: false,
      espaces: [],
      espace: null,
      flagHandelDossier: false,
      flagHandelFichier: false,
      flagAccess: false,
      idAccess: null,
      flagAdmin: false,
      domain: null
    };
  },

  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },

  components: {
    Loading,
    Create,
    TableFirst,
    Access,
    DeleteModel
  },

  computed: {

  },

  methods: {

    closeDeleteModel() {
      this.flagDeleteModel = false;
    },

    validerDeleteModel() {
      Espace.supprimer(this.idDeleteModel, this.originalnameDeleteModel, this.valueDeleteModel)
        .then((result) => {
          if (result == true) {
            const index = this.listeParent.findIndex((el) => el._id == this.idDeleteModel)
            this.listeParent.splice(index, 1);
            this.flagDeleteModel = false;
            this.messageDelete = "";
            this.idDeleteModel = null;
            this.originalnameDeleteModel = null;
            this.valueDeleteModel = null;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    checkTypeFile(value) {
      if (!value.includes('.')) {
        return 'Folder'; // Si aucune extension, c'est un dossier
      }

      const extention = value.split('.').pop().toLowerCase(); // Récupère la dernière extension en minuscules

      switch (extention) {
        // Documents
        case 'pdf':
          return 'Pdf';
        case 'txt':
          return 'Txt';
        case 'docx':
        case 'doc':
          return 'Word';
        case 'xls':
        case 'xlsx':
          return 'Excel';
        case 'csv':
          return 'CSV';
        case 'ppt':
        case 'pptx':
          return 'Power point';

        // Images
        case 'jpg':
        case 'jpeg':
          return 'Image Jpeg';
        case 'png':
          return 'Image PNG';
        case 'gif':
          return 'Image GIF';
        case 'bmp':
          return 'Image BMP';
        case 'webp':
          return 'Image WebP';
        case 'svg':
          return 'Image SVG';

        // Vidéos
        case 'mp4':
          return 'Vidéo MP4';
        case 'avi':
          return 'Vidéo AVI';
        case 'mov':
          return 'Vidéo MOV';
        case 'mkv':
          return 'Vidéo MKV';
        case 'flv':
          return 'Vidéo FLV';

        // Audio
        case 'mp3':
          return 'Audio MP3';
        case 'wav':
          return 'Audio WAV';
        case 'aac':
          return 'Audio AAC';
        case 'flac':
          return 'Audio FLAC';

        // Archives
        case 'zip':
          return 'Archive ZIP';
        case 'rar':
          return 'Archive RAR';
        case '7z':
          return 'Archive 7z';
        case 'tar':
          return 'Archive TAR';

        // Code & développement
        case 'html':
          return 'Fichier HTML';
        case 'css':
          return 'Fichier CSS';
        case 'js':
          return 'Fichier JavaScript';
        case 'json':
          return 'Fichier JSON';
        case 'xml':
          return 'Fichier XML';
        case 'php':
          return 'Fichier PHP';
        case 'py':
          return 'Fichier Python';
        case 'java':
          return 'Fichier Java';
        case 'cpp':
          return 'Fichier C++';
        case 'c':
          return 'Fichier C';
        case 'ts':
          return 'Fichier TypeScript';

        default:
          return 'Unknown file type';
      }
    },

    selectEspace($event) {
      this.isLoading = true;
      this.interlocuteurs = [];
      this.listeParent = [];
      this.flagHandelDossier = true;
      this.flagHandelFichier = true;

      for (let i = 0; i < this.preInterlocuteurs.length; i++) {
        if (this.preInterlocuteurs[i].clientId == $event.target.value && this.preInterlocuteurs[i].email != this.email) {
          this.interlocuteurs.push(this.preInterlocuteurs[i]);
        }
      }

      this.flagInterlocuteur = true;

      const index = this.espaces.findIndex((el) => el._id == $event.target.value);

      this.emailCompany = this.espaces[index].email;


      // Get my files or folders
      Espace.readParent(this.emailCompany)
        .then((result) => {

          if (result.data.listeParent.length == 0) {
            this.isLoading = false;
          }

          if (result.data.listeParent.length != 0) {
            for (let i = 0; i < result.data.listeParent.length; i++) {
              if (result.data.listeParent[i].emailInterlocuteur == this.email || result.data.listeParent[i].partager.includes(this.email)) {
                result.data.listeParent[i].status = false;
                this.listeParent.push(result.data.listeParent[i]);
              }
            }
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log(error)
        });
    },

    supprimer(id, originalname, value) {
      this.messageDelete = "Êtes-vous sûr de vouloir supprimer cet élément ?",
        this.idDeleteModel = id;
      this.originalnameDeleteModel = originalname;
      this.valueDeleteModel = value;
      this.flagDeleteModel = true;
    },

    downloadFile(response, title, typeContent) {

      var newBlob = new Blob([response.data], {
        type: typeContent
      });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = title;
      link.click();
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
      }, 100);

    },

    download(filename, value, originalname) {
      const extention = originalname.split('.').pop().toLowerCase();

      let typeContent = null;

      switch (extention) {
        // Formats Word
        case 'doc':
          typeContent = 'application/msword';
          break;
        case 'docx':
          typeContent = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
          break;
        case 'docm':
          typeContent = 'application/vnd.ms-word.document.macroEnabled.12';
          break;
        case 'dot':
          typeContent = 'application/msword';
          break;
        case 'dotx':
          typeContent = 'application/vnd.openxmlformats-officedocument.wordprocessingml.template';
          break;
        case 'dotm':
          typeContent = 'application/vnd.ms-word.template.macroEnabled.12';
          break;
        case 'rtf':
          typeContent = 'application/rtf';
          break;
        case 'odt':
          typeContent = 'application/vnd.oasis.opendocument.text';
          break;

        // Formats Excel
        case 'xls':
          typeContent = 'application/vnd.ms-excel';
          break;
        case 'xlsx':
          typeContent = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          break;
        case 'xlsm':
          typeContent = 'application/vnd.ms-excel.sheet.macroEnabled.12';
          break;
        case 'xltx':
          typeContent = 'application/vnd.openxmlformats-officedocument.spreadsheetml.template';
          break;
        case 'xltm':
          typeContent = 'application/vnd.ms-excel.template.macroEnabled.12';
          break;
        case 'csv':
          typeContent = 'text/csv';
          break;

        // Formats PDF
        case 'pdf':
          typeContent = 'application/pdf';
          break;

        // Formats PowerPoint
        case 'ppt':
          typeContent = 'application/vnd.ms-powerpoint';
          break;
        case 'pptx':
          typeContent = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
          break;

        // Archives
        case 'zip':
          typeContent = 'application/zip';
          break;
        case 'rar':
          typeContent = 'application/vnd.rar';
          break;
        case '7z':
          typeContent = 'application/x-7z-compressed';
          break;
        case 'tar':
          typeContent = 'application/x-tar';
          break;
        case 'gz':
          typeContent = 'application/gzip';
          break;

        // Images
        case 'jpeg':
        case 'jpg':
          typeContent = 'image/jpeg';
          break;
        case 'png':
          typeContent = 'image/png';
          break;
        case 'gif':
          typeContent = 'image/gif';
          break;
        case 'bmp':
          typeContent = 'image/bmp';
          break;
        case 'svg':
          typeContent = 'image/svg+xml';
          break;
        case 'webp':
          typeContent = 'image/webp';
          break;

        // Vidéos
        case 'mp4':
          typeContent = 'video/mp4';
          break;
        case 'avi':
          typeContent = 'video/x-msvideo';
          break;
        case 'mov':
          typeContent = 'video/quicktime';
          break;
        case 'wmv':
          typeContent = 'video/x-ms-wmv';
          break;
        case 'flv':
          typeContent = 'video/x-flv';
          break;
        case 'mkv':
          typeContent = 'video/x-matroska';
          break;

        // Audio
        case 'mp3':
          typeContent = 'audio/mpeg';
          break;
        case 'wav':
          typeContent = 'audio/wav';
          break;
        case 'ogg':
          typeContent = 'audio/ogg';
          break;
        case 'flac':
          typeContent = 'audio/flac';
          break;

        // Code & Web
        case 'html':
          typeContent = 'text/html';
          break;
        case 'css':
          typeContent = 'text/css';
          break;
        case 'js':
          typeContent = 'application/javascript';
          break;
        case 'json':
          typeContent = 'application/json';
          break;
        case 'xml':
          typeContent = 'application/xml';
          break;
        case 'php':
          typeContent = 'application/x-httpd-php';
          break;

        // Police (Fonts)
        case 'ttf':
          typeContent = 'font/ttf';
          break;
        case 'woff':
          typeContent = 'font/woff';
          break;
        case 'woff2':
          typeContent = 'font/woff2';
          break;

        // Fichiers par défaut (non pris en charge)
        default:
          typeContent = 'application/octet-stream';
          break;
      }


      Espace.download(filename, value)
        .then((result) => {
          this.downloadFile(result, originalname, typeContent);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    access(id) {
      this.idAccess = id;
      this.flagAccess = true;
    },

    validerAccess(interlocuteurs, idAccess) {


      Espace.validerAccess(interlocuteurs, idAccess)
        .then((result) => {
          if (result) {
            const index = this.listeParent.findIndex((el) => el._id == idAccess)
            this.listeParent[index].partager = [];
            this.listeParent[index].partager = interlocuteurs;
            this.flagAccess = false;
          }
        })
        .catch((error) => {
          console.log(error.message)
        });

    },

    closeAccess() {
      this.flagAccess = false;
    },


    retourPageParent() {
      this.flagTableFirst = false;
    },

    voir(id, index) {
      this.titreParent = this.listeParent[index].originalname;
      this.idParent = id;
      this.flagTableFirst = true;
    },


    delete(id) {
      Espace.delete(id)
        .then((result) => {
          if (result.data) {
            const index = this.myFiles.findIndex((el) => el._id == id);
            this.myFiles.splice(index, 1);
          }
        })
        .catch((error) => {
          console.log(error.message)
        });
    },

    closeConfirmation() {
      this.type = null;
      this.flagCreate = false;
    },

    // valider dossier
    async validerConfirmationDossier(name) {

      this.type = null;
      this.flagCreate = false;
      this.isLoading = true;

      if (this.flagAdmin) {

        Espace.soumetterDossier(this.emailCompany, name, this.email)
          .then((result) => {
            if (result) {
              this.listeParent.unshift(result.data);
              this.isLoading = false;
            } else {
              this.isLoading = false;
            }
          })
          .catch((error) => {
            console.log(error.message)
          });

      } else {

        Espace.soumetterDossier(this.emailCompany, name, this.email)
          .then((result) => {
            if (result) {
              // after create add acces valide by email
              this.listeParent.unshift(result.data);
              this.validerAccess([this.email], result.data._id);
              this.isLoading = false;
            } else {
              this.isLoading = false;
            }
          })
          .catch((error) => {
            console.log(error.message)
          });
      }

    },

    // valider fichier
    validerConfirmationFichier(document) {

      this.type = null;
      this.flagCreate = false;
      this.isLoading = true;

      if (this.flagAdmin) {

        Espace.soumetterFichier(this.emailCompany, document, this.email)
          .then((result) => {
            for (let i = 0; i < result.data.length; i++) {
              this.listeParent.unshift(result.data[i]);
            }
            result.data = [];
            this.isLoading = false;
          })
          .catch((error) => {
            console.log(error.message)
          });

      } else {

        Espace.soumetterFichier(this.emailCompany, document, this.email)
          .then((result) => {
            for (let i = 0; i < result.data.length; i++) {
              this.listeParent.unshift(result.data[i]);
              this.validerAccess([this.email], result.data[i]._id);
            }
            result.data = [];
            this.isLoading = false;
          })
          .catch((error) => {
            console.log(error.message)
          });
      }



    },

    handelDossier() {
      this.type = "dossier";
      this.flagCreate = true;
    },

    handelFichier() {
      this.type = "fichier";
      this.flagCreate = true;
    },

    deconnexion() {
      this.cookies.remove("accessToken");
      this.cookies.remove("refreshToken");
      return this.$router.push("/transfer");
    },


  },
  mounted() {

    if (this.cookies.get("accessToken") == null || this.cookies.get("accessToken") == "") {
      return this.$router.push("/transfer");
    } else {

      Interlocuteur.profile(this.cookies.get("accessToken"))
        .then((result) => {

          // Get info auth
          this.adresse = result.adresse;
          this.email = String(result.email);
          this.nom = result.nom;
          this.prenom = result.prenom;
          this.nomSociete = result.nomSociete;
          this.pays = result.pays;
          this.url = result.url;

          // check salarie ou client
          this.domain = this.email.split("@");

          if (this.domain[1] == "gthconsult.ma") {

            this.flagEspace = true;
            this.flagAdmin = true;

            const tabEmails = [
              "tarik.addioui",
              "jamal.ettariqi",
              "service.clients",
              "direction",
              "service.supports",
              "service.qhse",
            ];

            const flag = tabEmails.includes(this.domain[0]);

            // get Espaces
            Espace.read(this.cookies.get("accessToken"))
              .then((result) => {
                // check inspecteur ou admin
                if (flag) {
                  this.espaces = result;
                } else {
                  for (let i = 0; i < result.length; i++) {
                    if (String(result[i].raisonSocial).toLowerCase() == "gthconsult") {
                      this.espaces.push(result[i])
                    }
                  }
                }
              })
              .catch((error) => {
                console.log(error)
              });


            // get interlocuteurs
            Interlocuteur.read(this.cookies.get("accessToken"))
              .then((result) => {
                this.preInterlocuteurs = result;
              })
              .catch((error) => {
                console.log(error)
              });



          } else {

            this.flagEspace = true;
            this.flagAdmin = false;

            // Get folder and files
            Espace.readAccess(this.email)
              .then((result) => {
                this.listeParent = result.data.listeParent;
                this.emailCompany = result.data.email;
                this.flagHandelDossier = true;
                this.flagHandelFichier = true;
              })
              .catch((error) => {
                console.log(error)
              });
          }

        })
        .catch((error) => {
          console.log(error);
        });




    }

  }
};
</script>

<style scoped>
/* Start page */
* {
  font-family: Cairo, Verdana, Tahoma, Arial;
}

.dashboard {
  height: auto;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

/* End page */


/* Start Navbar */
.navbar {
  padding: 10px;
  margin: 0px;
  background-color: #e7e7e7c7;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: auto;
}

.navbar div img {
  height: 60px;
}

.navbar ul:nth-child(2) {
  padding: 15px;
  border: 1px solid white;
}

.navbar ul:nth-child(3) {
  padding: 5px;
  margin: 0;
  background-color: #053075;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
}

.navbar ul:nth-child(3) li {
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.navbar ul:nth-child(3) li span {
  font-size: small;
}

.fa-user {
  font-size: 30px;  /* Ajuste la taille selon tes besoins */
  color: whitesmoke;  /* Exemple de couleur */
  padding: 0;
  margin: 0;
}

.navbar ul li {
  color: white;
}



.navbar ul li button {
  background-image: linear-gradient(to right, #e52d27 0%, #b31217 51%, #e52d27 100%)
}

.navbar ul li button {
  margin: 5px;
  padding: 10px 35px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.navbar ul li button:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}


/* End Navbar */

/* Start conetnt */
.content {
  height: auto;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
}

/* Fin content */


/* Content Top */
.content .top {
  width: 100%;
  padding: 10px;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  -webkit-box-shadow: 5px 7px 36px 3px rgba(66, 68, 90, 1);
  -moz-box-shadow: 5px 7px 36px 3px rgba(66, 68, 90, 1);
  box-shadow: 5px 7px 36px 3px rgba(66, 68, 90, 1);
}

.raison-social {
  color: #0057e3;
  font-size: medium;
  font-weight: bolder;
}

.content .top select {
  border-radius: 25px;
  font-size: large;
  margin-left: 5px;
  margin-right: 5px;
  color: black;
  font-weight: 700;
  outline: 1px solid #a9a9a9;
  padding: 10px;
  background-color: #e7e7e7;
}

.content .top button {
  margin-left: 5px;
  margin-right: 5px;
  color: white;
  padding: 5px;
  transition: 1s;
  cursor: pointer;
  font-size: small;
  border-radius: 10px;
}

.content .top .creer-dossier {
  background-color: #00a419;
}

.content .top .creer-dossier:hover {
  background-color: green;
}


.content .top .televerser {
  background-color: orange;
}

.content .top .televerser:hover {
  background-color: orangered;
}


/* End content Top */


/* Start content center */
.content .center {
  height: auto;
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.content .center ul {
  height: auto;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.content .center ul li {
  width: 100%;
  padding: 5px;
  outline: 1px solid #0057e3;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
  border-radius: 50px;
  border-radius: 50px;
  border-radius: 25px;
  background: white;
  cursor: pointer;
}

.content .center ul li {
  width: 100%;
  height: 40px;
  padding: 5px;
  outline: 1px solid #0057e3;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
}

.content .center ul li p,
.content .center ul li button {
  margin-left: 20px;
}

.apercu,
.telecharger {
  color: white;
  background-color: #348334;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 25px;
}

.apercu:hover,
.telecharger:hover {
  background-color: green
}

.key {
  color: black;
  background-color: #e0d319;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 25px;

}

.key:hover {
  background-color: #ffee00;
}

.supprimer {
  color: white;
  background-color: #d31111b0;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 25px;
}

.supprimer:hover {
  background-color: red;
}


.fa {
  margin-right: 5px;
}

.folder i {
  color: #c35c00;
}

.file i {
  color: #4459c5;
}
</style>
