<template>
    <div name="modal-fade">

        <div class="modal-backdrop">
            <div class="modal">

                <header class="modal-header">
                    <slot v-if="type == 'fichier'" name="header">
                        Entrer le(s) fichier(s)
                    </slot>
                    <slot v-if="type == 'dossier'" name="header">
                        Entrer le dossier
                    </slot>
                    <button type="button" class="btn-close" @click="closeConfirmation">
                        x
                    </button>
                </header>

                <!-- uploder le dossier -->
                <section v-if="type == 'fichier'" class="modal-body">
                    <input type="file" ref="folder" @change="onFolderChange($event)" multiple />
                </section>

                <!-- Enter le dossier -->
                <section v-if="type == 'dossier'" class="modal-body">
                    <input type="text" v-model="name" />
                </section>


                <footer class="modal-footer">
                    <button type="button" class="btn-green" @click="validerConfirmation">
                        Oui
                    </button>
                    <button type="button" class="btn-red" @click="closeConfirmation">
                        Non
                    </button>
                </footer>

            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'ConfirmationModel',
    data() {
        return {
            flagTrop: false,
            name: "",
            document: null,   // Pour les fichiers
            folderFiles: []   // Pour le dossier
        }
    },

    props: {
        type: String
    },

    components: {
    },

    methods: {

        closeModal() {
            this.flagTrop = false;
            this.document = null;
            this.folderFiles = [];
            this.$emit('closeConfirmation');
        },

        // Gestion du dossier
        onFolderChange(e) {
            const files = Array.from(e.target.files);
            files.forEach(file => {
                // Calculer la taille du fichier en Mo
                const fileSizeInMB = file.size / (1024 * 1024); // Conversion des octets en 
                // Vous pouvez également effectuer une action en fonction de la taille
                if (fileSizeInMB > 50) {
                    const result = alert("La taille du fichier sélectionné dépasse la limite de 50 Mo. Veuillez choisir un fichier plus petit.");
                    if(result == undefined) {
                        this.closeConfirmation();
                    }
                } else {
                    this.document = Array.from(e.target.files);
                }
            });
        },

        closeConfirmation() {
            this.document = null;
            this.folderFiles = [];
            this.$emit('closeConfirmation');
        },

        validerConfirmation() {
            // Validation pour un fichier
            if (this.type == "fichier" && this.document != null) {
                this.$emit('validerConfirmationFichier', this.document);
            }

            // Validation pour un dossier
            if (this.type == "dossier") {
                this.$emit('validerConfirmationDossier', this.name);
            }
        }
    }
};
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* Fond sombre avec opacité */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.modal {
    background: #ffffff;
    min-width: 400px;
    max-width: 600px;
    border-radius: 12px;
    /* Coins arrondis */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    /* Ombre douce */
    overflow: hidden;
    animation: fadeIn 0.3s ease-in-out;
}

.modal-header,
.modal-footer {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-header {
    background-color: #f4f4f4;
    color: #333;
    font-size: 22px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
    position: relative;
}

.modal-body {
    padding: 20px;
    font-size: 16px;
    color: #555;
}

.modal-body input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s;
}

.modal-body input:focus {
    border-color: #4caf50;
}

.btn-close {
    position: absolute;
    top: 12px;
    right: 12px;
    background: transparent;
    border: none;
    font-size: 20px;
    color: #999;
    cursor: pointer;
    transition: color 0.3s;
}

.btn-close:hover {
    color: #333;
}

.modal-footer .btn-green,
.modal-footer .btn-red {
    margin-left: 5px;
    margin-right: 5px;
    width: 120px;
    padding: 12px;
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.btn-green {
    background-color: #4caf50;
    color: #fff;
}

.btn-green:hover {
    background-color: #45a049;
}

.btn-red {
    background-color: #f44336;
    color: #fff;
}

.btn-red:hover {
    background-color: #d32f2f;
}

/* Animation d'apparition */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>
